<template>
  <div>
    <h1 class="formTitle text-center mt-4">
      Se Parte de Nosotros
    </h1>
    <p class="text-center registerSubtitle mt-3">
      Suministra toda la información requerida
    </p>
    <v-form
      @submit="viewData($event)"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="Correo electrónico"
              type="email"
              required
              :rules="rules.email"
            ></v-text-field>
            <v-text-field
              v-model="pharmacyName"
              label="Nombre de la farmacia"
              type="text"
              required
              :rules="rules.pharmacyName"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="password"
              required
              label="Contraseña"
              type="password"
              :rules="rules.password"
            ></v-text-field>
            <v-text-field
              v-model="phoneNumber"
              required
              label="Teléfono"
              type="phone"
              :rules="rules.phoneNumber"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="addressPharmacy"
              required
              label="Dirección"
              type="address"
              :rules="rules.address"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-container>
            <v-file-input
              accept="image/*"
              label="Subir imagen"
              counter
              show-size
              truncate-length="15"
              @change="onFileSelected($event)"
            ></v-file-input>
          </v-container>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-select
            v-model="city"
            :items="cities"
            @change="OnSelectChange($event)"
            label="Selecciona un pueblo"
          >
          </v-select>
        </v-row>
      </v-container>
      <div class="row justify-content-center">
        <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
          <hr class="darkDivisor" />
          <h1 class="formTitle text-center mt-4">
            Horario
          </h1>
          <p class="text-center registerSubtitle mt-3">
            Horas de apertura y cierre de la farmacia
          </p>
          <v-container>
            <v-row
              v-for="(day, index) in daysObj"
              :key="index"
              class="day-container"
            >
              <v-col class="pa-2">
                <v-row>
                  <v-switch
                    style="margin-top: 27px;"
                    v-model="day.active"
                  ></v-switch>
                  <h5 class="hourLabel">{{ day.title }}</h5>
                </v-row>
              </v-col>
              <v-col>
                <v-select
                  v-model="day.beginHour"
                  :items="hours"
                  label="Apertura"
                  :disabled="!day.active"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="day.endHour"
                  :items="hours"
                  label="Cierre"
                  :disabled="!day.active"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="width: 100%;"
                color="success"
                type="subtmi"
              >
                Registrarse
              </v-btn>
              <router-link class="forgot-btn darkFont" to="/">
                <h5 class="text-center mt-5 darkFont">
                  Ya tienes cuenta? Iniciar sesión
                </h5>
              </router-link>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-form>
    <div v-if="backdrop" class="loader">
      <div :class="loader ? 'loader-show' : ''" class="loader-container ">
        <RotateSquare2 />
        <p class="loader-text">
          Cargando...
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare2 } from "vue-loading-spinner";
import Parse from "parse";
import ciudades from "../assets/ciudades.json";
export default {
  components: {
    RotateSquare2
  },
  data() {
    return {
      rules: {
        email: [
          v => !!v || "El email es requerido",
          v =>
            (v && v.length <= 50) ||
            "El email no puede contener mas de 50 letras"
        ],
        password: [v => !!v || "El password es requerido"],
        pharmacyName: [v => !!v || "El nombre de la farmacia es requerido"],
        phoneNumber: [v => !!v || "El numero telefonico es requerido"],
        address: [v => !!v || "La dirrecion de la farmacia es requerida"]
      },
      hours: [
        "12:00AM",
        "12:30AM",
        "1:00AM",
        "1:30AM",
        "2:00AM",
        "2:30AM",
        "3:00AM",
        "3:30AM",
        "4:00AM",
        "4:30AM",
        "5:00AM",
        "5:30AM",
        "6:00AM",
        "6:30AM",
        "7:00AM",
        "7:30AM",
        "8:00AM",
        "8:30AM",
        "9:00AM",
        "9:30AM",
        "10:00AM",
        "10:30AM",
        "11:00AM",
        "11:30AM",
        "12:00PM",
        "12:30PM",
        "1:00PM",
        "1:30PM",
        "2:00PM",
        "2:30PM",
        "3:00PM",
        "3:30PM",
        "4:00PM",
        "4:30PM",
        "5:00PM",
        "5:30PM",
        "6:00PM",
        "6:30PM",
        "7:00PM",
        "7:30PM",
        "8:00PM",
        "8:30PM",
        "9:00PM",
        "9:30PM",
        "10:00PM",
        "10:30PM",
        "11:00PM",
        "11:30PM"
      ],
      daysObj: [
        {
          title: "Lunes",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        },
        {
          title: "Martes",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        },
        {
          title: "Miércoles",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        },
        {
          title: "Jueves",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        },
        {
          title: "Viernes",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        },
        {
          title: "Sábado",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        },
        {
          title: "Domingo",
          active: false,
          beginHour: "12:00AM",
          endHour: "12:00AM"
        }
      ],
      city: "",
      cities: [],
      addressPharmacy: "",
      pharmacyName: "",
      phoneNumber: "",
      email: "",
      password: "",
      valid: true,
      backdrop: false,
      loader: false,
      nameStore: "",
      schedule: [],
      citiesJson: ciudades,
      zipcodeList: [],
      currentProfilePicture: undefined
    };
  },
  mounted() {
    this.cities = this.citiesJson.map(x => x.city);
  },
  methods: {
    viewData(e) {
      e.preventDefault();
      if (!this.$refs.form.validate()) {
        this.$swal({
          title: "Atencion",
          text: "Debe completar todos los campos",
          icon: "warning"
        });
        return;
      }
      this.backdrop = true;
      setTimeout(() => {
        this.loader = true;
      }, 200);
      this.schedule = [];
      this.daysObj.forEach(x => {
        if (x.active) {
          this.schedule.push({
            day: x.title,
            start: x.beginHour,
            end: x.endHour
          });
        }
      });
      this.signUp();
    },
    OnSelectChange(e) {
      this.zipcodeList = this.citiesJson.filter(x => x.city == e)[0].zipCode;
    },
    onFileSelected(e) {
      this.currentProfilePicture = e;
    },
    async signUp() {
      try {
        const user = new Parse.User();
        user.set("email", this.email);
        user.set("password", this.password);
        user.set("username", this.email);
        user.set("superAdmin", false);
        user.set("isAdmin", true);
        let newUser = await user.signUp();
        const Store = Parse.Object.extend("Store");
        const store = new Store();
        store.set("Description", this.storeDescription);
        store.set("Name", this.pharmacyName);
        store.set("Phone", parseInt(this.phoneNumber + ""));
        store.set("Schedule", this.schedule);
        store.set("Address", this.addressPharmacy);
        if (this.currentProfilePicture != undefined) {
          const parseFile = new Parse.File("img", this.currentProfilePicture);
          store.set("storePicture", parseFile);
        }
        store.set("isApproved", false);
        store.set("city", this.city);
        store.set("activate", false);
        store.set("logkey", btoa(this.password));
        store.set("zipcodes", this.zipcodeList);
        await store.save({ employee: newUser });
        localStorage.setItem(
          "usuarioFarmacia",
          JSON.stringify({
            admin: true,
            email: this.email,
            name: user.get("fullName"),
            rol: "E",
            status: true,
            editable: false,
            accountId: user.get("stripeAccountId"),
            stripeAccountId: user.get("stripeAccountId") ? true : false
          })
        );
        this.$swal({
          title: "Exito",
          text: "Usuario creado correctamente",
          icon: "success"
        }).then(() => {
          this.$router.push("/orders");
        });
      } catch (error) {
        this.$swal({
          title: "Error",
          text: "Ah ocurrido un error",
          html: error,
          icon: "error"
        });
      } finally {
        this.isProcessing = false;
        this.loader = false;
        this.backdrop = false;
      }
    }
  }
};
</script>
<style scoped>
.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loader-show {
  transform: scale(1) !important;
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}
.hourLabel {
  padding: 30px 30px 0px 30px;
  padding-right: 0px;
  text-align: center;
}
.darkDivisor {
  border-style: none;
  height: 3px;
  width: 85%;
  margin: auto;
  display: block;
  background-color: #1e1e1e;
  margin-top: 30px;
}
</style>
